<template>
  <div class="card statistics-card">
    <div class="card-body text-center">
      <h6 class="earnings-title">{{ title }}</h6>
      <h3 class="earnings-amount">{{ count }}</h3>
      <span
        v-if="badge.label"
        :class="`badge d-block bg-gradient-${badge.color} mb-2`"
      >
        {{ badge.label }}
      </span>
      <p v-if="description" class="earnings-description">{{ description }}</p>
      <button
        v-if="action.label"
        @click="handleClick"
        class="btn btn-outline-white btn-sm px-4"
      >
        {{ action.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",
  props: {
    title: {
      type: String,
      default: "Estimated Earnings",
    },
    description: {
      type: String,
      default: "",
    },
    count: {
      type: [Number, String],
      default: "$1,875",
    },
    badge: {
      type: Object,
      default: () => ({
        color: "dark",
        label: "",
      }),
    },
    action: {
      type: Object,
      default: () => ({
        label: "Manage",
      }),
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleClick() {
      if (this.onClick && typeof this.onClick === "function") {
        this.onClick();
      }
    },
  },
};
</script>

<style scoped>
/* Card Background */
.statistics-card {
  background-color: #20c997; /* Solid green background */
  border-radius: 12px; /* Smooth rounded corners */
  box-shadow: none; /* Remove any overlay effect */
  overflow: visible;
  transition: all 0.3s ease;
}

/* Title */
.earnings-title {
  font-size: 1rem; /* Adjust title size */
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10px;
}

/* Count */
.earnings-amount {
  font-size: 2rem; /* Adjusted size */
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}

/* Description */
.earnings-description {
  font-size: 0.85rem;
  color: #ffffff;
  margin-bottom: 15px;
}

/* Button */
.btn-outline-white {
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 5px 20px;
}

.btn-outline-white:hover {
  background-color: #ffffff;
  color: #20c997;
  transition: 0.3s ease;
}
</style>
