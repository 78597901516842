<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Proposals</h5>

            <button @click="exportAll" style="color: white; margin-top: 25px; margin-left: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding:10px; font-size: 12px;" class="mb-0 btn bg-gradient-primary btn-sm">
    <i class="fa fa-download me-1"></i> Export All (CSV)
</button>
          </div>


          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Date</th>
                  <th>Client Name</th>
                  <th>Status</th>
                  <th>X-Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="proposal in computedProposals" :key="proposal.id">
                  <td>{{ formatDate(proposal.created_at) }}</td>
                  <td>
              <router-link :to="{ name: 'General', params: { accountId: proposal.id } }" :title="proposal.client_name">
                {{ truncateText(proposal.client_name, 40) }}
              </router-link>
            </td>
                  <td>{{ proposal.is_finished ? 'Completed' : 'Active' }}</td>
                  <td>{{ proposal.x_date || 'N/A' }}</td>
                  <td class="actions">
                    <a :href="proposal.clientReportUrl" class="action-link" target="_blank">
                      <i class="fas fa-eye"></i> View Client Report
                    </a>
                    <a :href="proposal.producerReportUrl" class="action-link" target="_blank">
                      <i class="fas fa-industry"></i> View Producer Report
                    </a>
                    <a href="#" :onclick="'downloadData(' + proposal.id + '); return false;'" class="action-link" target="_blank">
                      <i class="fas fa-download"></i> Export Data
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="!computedProposals.length" class="mt-4">No proposals yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { DataTable } from "simple-datatables";
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: "ProposalsDataTable",
  async mounted() {
    await this.loadProposals();
    this.$nextTick(() => {
      new DataTable("#datatable-search", {
        searchable: true,
        fixedHeight: true
      });
    });

    window.downloadData = this.downloadData.bind(this);

  },
  computed: {
    ...mapGetters('proposal', ['allProposals']),
    computedProposals() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL.replace('/api/vue', '');

      return this.allProposals.map(proposal => ({
        ...proposal,
        clientReportUrl: `${baseUrl}/report/${proposal.id}/redacted/none`,
        producerReportUrl: `${baseUrl}/report/${proposal.id}/producer`
      }));
    }
  },
  methods: {
    ...mapActions('proposal', ['fetchProposals']),
    async loadProposals() {
      await this.fetchProposals();
    },
    formatDate(date) {
      return format(parseISO(date), 'eeee, MMMM do');
    },
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + '...';
    },
    exportAll() {
      const token = localStorage.getItem('access_token');
    if (token) {
      // Make an API request to the download CSV endpoint
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/download/csv`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob'  // Important for handling binary data
      })
      .then(response => {
        // Create a URL for the CSV file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `proposals_all.csv`);  // Name the CSV file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Download failed', error);
        alert('Failed to download the CSV file. Please try again.');
      });
    } else {
      console.error('No access token found in localStorage');
      alert('No access token found. Please log in.');
    }
    },
    downloadData(proposalId) {
    const token = localStorage.getItem('access_token');
    if (token) {
      // Make an API request to the download CSV endpoint
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/download/csv/${proposalId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob'  // Important for handling binary data
      })
      .then(response => {
        // Create a URL for the CSV file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `proposal_${proposalId}.csv`);  // Name the CSV file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Download failed', error);
        alert('Failed to download the CSV file. Please try again.');
      });
    } else {
      console.error('No access token found in localStorage');
      alert('No access token found. Please log in.');
    }
  }
  }
};
</script>

<style scoped>
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.action-link {
  display: inline-block;
  margin-right: 10px;
  color: #007bff;
  cursor: pointer;
}
.action-link i {
  margin-right: 5px;
}
</style>
