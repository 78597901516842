<template>
  <div>
    <!-- Service List Card -->
    <div class="card">


      <!-- Service Table -->
      <div class="p-3 pt-0 card-body">
        <table class="table align-middle mb-0">
          <thead>
  <tr class="align-middle" style="height: 70px;">
    <th style="vertical-align: bottom;" scope="col" class="ps-3 fw-bold text-muted">Service Strategy</th>
    <th style="vertical-align: bottom;">High Savings</th>
    <th style="vertical-align: bottom;" scope="col" class="text-center fw-bold text-muted">Low Savings</th>
    <th style="vertical-align: bottom;" scope="col" class="text-end pe-3">
      <div 
  class="flex items-center cursor-pointer text-blue-500 hover:text-blue-700"
  @click="openModal"
>
  <span class="mr-2" style="position:relative; top:2px;">Add New Service</span>
  <svg style="margin-left: 10px;" width="21" height="21" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0336 9.93903C20.0336 7.30017 18.9631 4.90895 17.234 3.18071C15.5049 1.45246 13.1145 0.381104 10.4757 0.381104C7.83679 0.381104 5.44558 1.45159 3.71733 3.18071C1.98908 4.90982 0.917725 7.30017 0.917725 9.93903C0.917725 12.5779 1.98821 14.9691 3.71733 16.6974C5.44644 18.4256 7.83679 19.497 10.4757 19.497C13.1145 19.497 15.5057 18.4265 17.234 16.6974C18.9622 14.9682 20.0336 12.5779 20.0336 9.93903ZM18.2958 9.93903C18.2958 12.0991 17.4217 14.0524 16.0053 15.4687C14.589 16.885 12.6357 17.7592 10.4757 17.7592C8.31556 17.7592 6.36227 16.885 4.94596 15.4687C3.52965 14.0524 2.65553 12.0991 2.65553 9.93903C2.65553 7.77894 3.52965 5.82565 4.94596 4.40934C6.36227 2.99302 8.31556 2.11891 10.4757 2.11891C12.6357 2.11891 14.589 2.99302 16.0053 4.40934C17.4217 5.82565 18.2958 7.77894 18.2958 9.93903ZM7.00004 10.8079H9.60675V13.4146C9.60675 13.8943 9.99602 14.2835 10.4757 14.2835C10.9553 14.2835 11.3446 13.8943 11.3446 13.4146V10.8079H13.9513C14.4309 10.8079 14.8202 10.4187 14.8202 9.93903C14.8202 9.4594 14.4309 9.07013 13.9513 9.07013H11.3446V6.46342C11.3446 5.98379 10.9553 5.59452 10.4757 5.59452C9.99602 5.59452 9.60675 5.98379 9.60675 6.46342V9.07013H7.00004C6.52041 9.07013 6.13114 9.4594 6.13114 9.93903C6.13114 10.4187 6.52041 10.8079 7.00004 10.8079Z" fill="#50E3C2"/>
</svg>

</div>

    </th>
  </tr>
</thead>



          <tbody>
            <tr v-for="(service, index) in services" :key="index">
              <!-- Checkbox and Service Name -->
              <td class="d-flex align-items-center ps-3">
                <div class="me-2 cursor-pointer" @click="service.isChecked = !service.isChecked; confirmUnassign(service, $event)">


  <svg v-if="service.isChecked"
  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.6097" cy="13.0732" r="12.5122" fill="#50E3C2"/>
<path d="M17.6793 8.41086L10.5244 15.5658L7.54025 12.5816C7.26845 12.3098 6.82844 12.3098 6.55734 12.5816C6.28625 12.8534 6.28555 13.2934 6.55734 13.5645L10.033 17.0401C10.3047 17.3119 10.7448 17.3119 11.0159 17.0401L18.6622 9.39376C18.934 9.12197 18.934 8.68196 18.6622 8.41086C18.3904 8.13976 17.9504 8.13907 17.6793 8.41086Z" fill="#132442"/>
</svg>
<svg v-else width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.6097" cy="12.683" r="11.8171" fill="#E5E5E5" stroke="#2333AD" stroke-width="1.39024"/>
</svg>

</div>

                <label :for="service.id" class="mb-0 fw-bold text-dark">
                  {{ service.name }}
                </label>
              </td>

              <!-- High Savings -->
              <td class="text-center fw-bold text-dark">
                {{ formatCurrency(service.highSavings) }}
              </td>

              <!-- Low Savings -->
              <td class="text-center fw-bold text-dark">
                {{ formatCurrency(service.lowSavings) }}
              </td>

              <!-- Edit Savings -->
              <td class="text-end pe-4">
                <a href="#" class="text-primary fw-bold" @click="openEditModal(service)">
                  Edit Savings
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Add Service Modal -->
    <div v-if="isModalOpen" class="modal-wrapper">
      <!--div class="background-overlay" @click="closeModal"></div-->
      <div class="modal-container">
        <h2 class="title">Add New Service</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="serviceSelection" class="form-label">Select Service</label>
            <select
              id="serviceSelection"
              v-model="newService"
              class="form-input"
              required
            >
              <option
                v-for="(service, index) in filteredServices"
                :key="index"
                :value="service"
              >
                {{ service }}
              </option>
            </select>
          </div>
          <div class="button-container">
            <button type="button" class="cancel-button" @click="closeModal">Cancel</button>
            <button type="submit" class="save-button">Add Service</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Edit Savings Modal -->
    <div v-if="isEditModalOpen" class="modal-wrapper">
      <!--div class="background-overlay" @click="closeEditModal"></div-->
      <div class="modal-container">
        <h2 class="title">Edit Savings</h2>
        <form @submit.prevent="submitEditSavings">
          <div class="form-group">
            <label for="highSavings" class="form-label">High Savings</label>
            <input
              id="highSavings"
              v-model="selectedService.highSavings"
              type="number"
              class="form-input"
              required
            />
          </div>
          <div class="form-group">
            <label for="lowSavings" class="form-label">Low Savings</label>
            <input
              id="lowSavings"
              v-model="selectedService.lowSavings"
              type="number"
              class="form-input"
              required
            />
          </div>
          <div class="button-container">
            <button type="button" class="cancel-button" @click="closeEditModal">Cancel</button>
            <button type="submit" class="save-button">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from "axios";

export default {
  name: "TodoList",
  props: {
    header: {
      type: Object,
      required: true,
    },
    id: {
      type: Number, // The ID of the QuestionnaireSession
      required: true,
    },
  },
  data() {
    return {
      services: [],
      isModalOpen: false, // Controls modal visibility
      isEditModalOpen: false,
      allServices: [], // All available services fetched from the general API
      newService: "",
    };
  },
  computed: {
    filteredServices() {
      // Remove services already in the `services` array from `allServices`
      const existingServiceNames = this.services.map((service) => service.name);
      return this.allServices.filter(
        (service) => !existingServiceNames.includes(service)
      );
    },
  },
  methods: {
      // Open Edit Savings Modal
  openEditModal(service) {
    console.log("EDIT SERVICE SAVINGS: " , service);
    this.selectedService = { ...service }; // Clone the service data
    this.isEditModalOpen = true;
  },

  // Close Edit Savings Modal
  closeEditModal() {
    this.isEditModalOpen = false;
    this.selectedService = {};
  },
  formatCurrency(value) {
    if (value == null) return "$0"; // Handle null or undefined values
    return `$${Number(value).toLocaleString()}`; // Add $ and format with commas
  },

  submitEditSavings() {
    const payload = {
      proposal_id: this.id, // Pass the `proposal_id` from the `id` prop
      service_name: this.selectedService.name, // Pass the name of the selected service
      high_savings: this.selectedService.highSavings, // Updated high savings
      low_savings: this.selectedService.lowSavings, // Updated low savings
    };

    console.log("SubmitEditSavings: ", payload);
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found");
      return;
    }

    axios
      .post(`${process.env.VUE_APP_API_BASE_URL}/services-override`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log("Savings updated successfully:", response.data.message);

        // Update the local `services` array with the updated values
        const serviceIndex = this.services.findIndex(
          (service) => service.name === this.selectedService.name
        );
        if (serviceIndex !== -1) {
          this.services[serviceIndex].highSavings = this.selectedService.highSavings;
          this.services[serviceIndex].lowSavings = this.selectedService.lowSavings;
        }

        this.closeEditModal(); // Close the modal after saving
      })
      .catch((error) => {
        alert("error saving");
        console.error("Error updating savings:", error.response || error.message);
      });
  },


  async confirmUnassign(service, event) {
  // Check the current state of the checkbox
  const isChecked = event.target.checked;

  console.log("Service: ", service);

  // Check if the service is "Consulting"
  if (service.name === "Consulting") {
    // Show a SweetAlert error message
    await this.$swal({
      title: "Not Supported",
      text: "You cannot remove the Consulting service.",
      icon: "error",
      timer: 1150, // Automatically close after 1 second
      showConfirmButton: false, // No confirm button
      customClass: {
        popup: "swal-popup-error", // Optional custom class for styling
      },
    });

    // Revert the checkbox to its previous state
    service.isChecked = true;
    return; // Exit the method to prevent further processing
  }

  if (!isChecked) {
    // Show confirmation dialog using SweetAlert
    const result = await this.$swal({
      title: "Are you sure?",
      text: "Do you want to unassign this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, unassign it!",
      cancelButtonText: "No, keep it!",
      customClass: {
        confirmButton: "btn bg-gradient-success",
        cancelButton: "btn bg-gradient-danger",
      },
      buttonsStyling: false,
    });

    if (result.isConfirmed) {
      // User confirmed: proceed with the API call
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }

        // Prepare payload for the API
        const payload = {
          selected_service: service.name, // Adjusting to match Laravel's `removeService` method
          session_id: this.id, // Assuming `id` is the `session_id` passed as a prop
        };

        // Make the API call
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/removeService`,
          payload,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log("Service unassigned:", response.data.message);

        // Remove the service locally from the list
        this.services = this.services.filter(
          (item) => item.name !== service.name
        );

        // Update the service's state locally (if needed for UI refresh)
        service.isChecked = false;
      } catch (error) {
        console.error(
          "Error unassigning service:",
          error.response?.data || error.message
        );
        // Revert the checkbox to checked state on failure
        service.isChecked = true;
      }
    } else {
      // User cancelled: revert the checkbox to checked
      service.isChecked = true;
    }
  }
},


    openModal() {
      this.isModalOpen = true;
      if (this.filteredServices.length > 0) {
        this.newService = this.filteredServices[0];
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async submitForm() {
      if (!this.newService) {
        console.error("No service selected");
        return;
      }

      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }

        const payload = {
          selected_service: this.newService,
          session_id: this.id,
        };

        await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/addService`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        //this.services.push(response.data.service);
        
        this.newService = "";
        this.closeModal();
        await this.fetchServices();
      } catch (error) {
        console.error("Error adding service:", error.response || error.message);
      }
    },
    async fetchServices() {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/getServices/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        this.services = response.data.services.map((service) => ({
          id: service.id,
          name: service.title,
          date: new Date().toLocaleDateString(),
          highSavings: service.high_range,
          lowSavings: service.low_range, // Restored Low Savings
          isChecked: true
        }));
      } catch (error) {
        console.error("Error fetching services:", error.response || error.message);
      }
    },
    async fetchAllServices() {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/getServices`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.allServices = response.data.services;
      } catch (error) {
        console.error("Error fetching all services:", error.response || error.message);
      }
    },
  },
  async mounted() {
    await this.fetchServices();
    await this.fetchAllServices();
  },
};
</script>

<style scoped>
/* Table Header Styling */
th {
  padding: 8px 12px; /* Set consistent padding for all header cells */
  vertical-align: middle; /* Ensures vertical centering */
  font-size: 12px;
  font-weight: 600;
  color: #6c757d;
  text-transform: uppercase;
}

/* Button Styling */
.btn-sm {
  font-size: 12px; /* Small button font */
  line-height: 1.2; /* Adjust button line-height to fit header */
  padding: 4px 8px; /* Tighter padding */
  white-space: nowrap; /* Prevents text wrapping */
}

.d-flex {
  display: flex;
}

.align-middle {
  vertical-align: middle; /* Align all row content to center */
}

.h-100 {
  height: 100%; /* Ensure button container takes full header height */
}


.justify-content-end {
  justify-content: flex-end; /* Right-align the button */
}


.table {
  width: 100%;
  margin-bottom: 0;
  font-size: 14px;
}

.table thead th {
  color: #6c757d;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 8px;
  border-bottom: 2px solid #dee2e6;
}

.table td {
  padding: 12px 8px;
  vertical-align: middle;
}

.form-check-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.text-primary {
  color: #007bff !important;
  text-decoration: none;
}

.text-primary:hover {
  text-decoration: underline;
}

.fw-bold {
  font-weight: 600;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button,
.save-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.cancel-button {
  background-color: #6c757d;
  color: #fff;
}

.save-button {
  background-color: #007bff;
  color: #fff;
}

.save-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}

</style>
