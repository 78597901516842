<template>
  <div class="page-container">
    <!-- Left Sidebar: Glossary Index -->
    <div class="glossary-index">
      <h2 class="index-header">Glossary Index</h2>

      <!-- Search Bar -->
      <input
        type="text"
        v-model="searchQuery"
        class="search-bar"
        placeholder="Search terms..."
      />

      <ul>
        <li
          v-for="item in filteredGlossaryItems"
          :key="item.term"
          @click="scrollTo(item.term)"
          class="index-item"
        >
          {{ item.term }}
        </li>
      </ul>
    </div>

    <!-- Main Content Area: Scrollable Glossary -->
    <div class="glossary-content" ref="glossaryContent">
      <div
        v-for="item in filteredGlossaryItems"
        :key="item.term"
        :id="item.term"
        class="glossary-item"
      >
        <h3 class="term-title">
          {{ item.term }}
        </h3>
        <div v-html="item.definition" class="glossary-description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      glossaryItems: [], // Glossary data fetched from the API
      searchQuery: "", // Search query to filter glossary items
    };
  },
  computed: {
    filteredGlossaryItems() {
      // Filter glossary items based on the search query
      if (!this.searchQuery.trim()) {
        return this.glossaryItems;
      }
      const query = this.searchQuery.toLowerCase();
      return this.glossaryItems.filter(
        (item) =>
          item.term.toLowerCase().includes(query) ||
          item.content.toLowerCase().includes(query)
      );
    },
  },
  mounted() {
    // Fetch data from the API
    //console.log("HELLO HELOO 12");
    fetch(`${process.env.VUE_APP_API_BASE_URL}/glossary-items`)
      .then((response) => {
        //console.log("GLOSSARY RESPONSE: ", response.json());
        if (!response.ok) {
          // Log detailed error messages for non-200 responses
          return response.json().then((errorDetails) => {
            throw new Error(
              `Failed to fetch glossary items: ${response.status} ${response.statusText} - ${JSON.stringify(
                errorDetails
              )}`
            );
          });
        }
        return response.json();
      })
      .then((data) => {
        //console.log("GLOSSARY ITEMS: ", data.glossary_items);
        this.glossaryItems = data.glossary_items; // Populate glossary data
      })
      .catch((error) => {
        console.error("Error fetching glossary items:", error.message);
        console.error("Full Error Object:", error);
      });
  },
  methods: {
    scrollTo(term) {
      const container = this.$refs.glossaryContent; // Get the scrollable container
      const target = document.getElementById(term); // Get the target element by ID

      if (container && target) {
        // Calculate the offset of the target relative to the container
        const targetPosition = target.offsetTop - container.offsetTop;

        // Scroll the container smoothly to the target position
        container.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
/* Page Container */
.page-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  justify-content: center; /* Centers the entire layout horizontally */
  align-items: center; /* Centers the layout vertically */
}

/* Glossary Index (Sidebar) */
.glossary-index {
  width: 35%; /* Adjusted width for sidebar */
  background-color: #ffffff;
  border-right: 2px solid #e0e0e0;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  border-radius: 8px;
}

.index-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
}

.search-bar {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.search-bar:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.glossary-index ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.index-item {
  cursor: pointer;
  padding: 10px 0;
  color: #007bff;
  font-size: 1.1rem;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.index-item:hover {
  color: #0056b3;
}

/* Glossary Content */
.glossary-content {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  flex: 0 0 50%; /* Slightly widened content area (50% of parent width) */
  margin-left: 20px; /* Space between the sidebar and content */
}

.glossary-item {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.term-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.glossary-description {
  font-size: 1.1rem;
  color: #555555;
  line-height: 1.8;
  text-align: justify;
}

/* Accessibility Enhancements */
.index-item,
.term-title {
  font-size: 1.3rem; /* Slightly larger text for easier reading */
}

.glossary-index,
.glossary-content {
  height: calc(100vh - 40px); /* Ensure proper scrolling within the viewport */
}
</style>
