<template>
    <div>
      <iframe
      :src="iframeSrc"
      frameborder="0"
      style="width: 210mm; height: 297mm; border: none; overflow: hidden;"
      allowfullscreen
    ></iframe>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ReportWebview",
    data() {
      return {
        iframeSrc: "",
      };
    },
    async mounted() {
      try {
        const { sessionId, hiddenQuestionsIn } = this.$route.params;
        const accessToken = localStorage.getItem("access_token");
        const baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
        const url = `${baseUrl}/report-webview/${sessionId}/${hiddenQuestionsIn}`;
  
        // Verify if the URL is accessible
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.status === 200) {
          this.iframeSrc = url;
        } else {
          console.error("Error: Unable to load the report webview.");
          alert(`Failed to load report webview: ${response.status}`);
        }
      } catch (error) {
        console.error("Error loading report webview:", error);
        const errorMessage = error.response
          ? `Status: ${error.response.status} - ${error.response.data.message || error.message}`
          : error.message;
        alert(`Error loading report: ${errorMessage}`);
      }
    },
  };
  </script>
  
  <style scoped>
iframe {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  display: block;
}
  </style>
  