<template>
  <div class="card announcement-card border-0 shadow-sm">
    <div class="p-3 card-body d-flex align-items-stretch">
      <!-- Left Section -->
      <div class="left-content d-flex flex-column justify-content-center p-4">
        <h6 class="mb-1 font-weight-bold">Campaign Management</h6>
        <p class="text-muted text-sm mb-0">
          9-Bullets to Bind-it is our flagship campaign automation tool. It is designed to automate your campaigns and client correspondence.
        </p>
      </div>

      <!-- Divider -->
      <div class="vertical-divider"></div>

      <!-- Right Section -->
      <div class="right-content d-flex align-items-center justify-content-between px-4">
        <div class="d-flex flex-column justify-content-center">
          <p class="text-sm mb-1">
            <strong>Standard Campaign:</strong> {{ summary.progress }}%
          </p>
          <p class="text-sm mb-1">
            <strong>Expiration Date:</strong> {{ summary.expirationDate }}
          </p>
        </div>
        <div class="action-button ms-4">
          <a :href="action.route" class="btn btn-primary text-white rounded px-4">
            {{ action.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnouncementCard",
  props: {
    action: {
      type: Object,
      default: () => ({ route: "#", label: "9-Triggers to Bind-it" }),
    },
    summary: {
      type: Object,
      default: () => ({
        progress: 57,
        expirationDate: "October 31, 2024",
      }),
    },
  },
};
</script>

<style scoped>
.announcement-card {
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
}

.left-content {
  flex: 0 0 400px; /* Fixed width for left content */
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-content h6 {
  font-size: 1rem;
  color: #2b2b2b;
  margin-bottom: 0.5rem;
}

.left-content p {
  color: #6c757d;
  line-height: 1.5;
}

.vertical-divider {
  width: 1px;
  background-color: #dee2e6;
  height: auto;
  margin: 0; /* Ensure no spacing affects the position */
}

.right-content {
  flex: 1; /* Allow right section to take the remaining space */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
}

.right-content p {
  font-size: 0.875rem;
  margin: 0;
  color: #2b2b2b;
}

.action-button .btn {
  background-color: #0047b3;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 50px;
  transition: background 0.3s;
}

.action-button .btn:hover {
  background-color: #003399;
}

.ms-4 {
  margin-left: 1rem;
}
</style>
