<template>
    <div class="mb-3 card">
      <div class="p-3 card-body">
        <div
          class="d-flex"
          :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
        >
          <!-- Icon Container -->
          <div
            class="icon-container"
            :class="rowReverse ? 'me-2' : ''"
          >
            <!-- Render SVG -->
            <div v-if="icon.svg" v-html="icon.svg" class="svg-wrapper"></div>
          </div>
          
          <!-- Content Section -->
          <div :class="classContent">
            <div class="numbers">
              <p
                class="mb-0 text-sm text-uppercase font-weight-bold"
                :class="title.color"
              >
                {{ typeof title === "string" ? title : title.text }}
              </p>
              <h5 :class="`mb-0 font-weight-bolder ${value.color}`">
                {{
                  (value && typeof value === "string") ||
                  (value && typeof value === "number")
                    ? value
                    : value.text
                }}
                <span
                  v-if="percentage && typeof percentage === 'string'"
                  class="text-sm font-weight-bolder"
                >
                  {{ percentage }}
                </span>
                <span
                  v-if="percentage && typeof percentage === 'object'"
                  :class="`text-sm font-weight-bolder text-${percentage.color}`"
                >
                  {{ percentage.value }}
                </span>
              </h5>
              <p v-if="description" class="mt-2 mb-0" v-html="description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MiniStatisticsCardSvg",
    props: {
      rowReverse: {
        type: Boolean,
        default: false,
      },
      title: {
        type: [Object, String],
        required: true,
        text: {
          type: String,
        },
        color: {
          type: String,
        },
      },
      description: {
        type: String,
        default: "",
      },
      value: {
        type: [Object, String, Number],
        required: true,
        text: {
          type: [String, Number],
        },
        color: {
          type: String,
        },
      },
      percentage: {
        type: [Object, String],
        value: {
          type: String,
        },
        color: {
          type: String,
        },
        default: () => ({
          color: "success",
        }),
      },
      icon: {
        type: Object,
        svg: {
          type: String, // Pass SVG data as a string
          required: true,
        },
        default: () => ({}),
      },
      classContent: {
        type: String,
        default: "",
      },
    },
  };
  </script>
  
  <style scoped>
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  </style>
  