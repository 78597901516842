<template>
  <div class="card card-carousel overflow-hidden h-100 p-0">
    <div
      id="carouselExampleCaptions"
      class="carousel slide h-100"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner border-radius-lg h-100">
        <div
          v-for="(
            { img, title, description, icon: { component, background } }, index
          ) of items"
          :key="index"
          :class="`carousel-item h-100 ${index === 0 ? 'active' : ''}`"
        >
          <div class="image-wrapper">
            <img :src="img" alt="carousel image" class="carousel-image"/>
          </div>
          <div
            class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5"
          >
            <div
              :class="`icon icon-shape icon-sm ${background} text-center border-radius-md mb-3`"
            >
              <i :class="`opacity-10 ${component}`"></i>
            </div>
            <h5 class="text-info mb-1">{{ title }}</h5>
            <p>
              {{ description }}
            </p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    items: {
      type: Array,
      required: true,
      img: String,
      title: String,
      description: String,
      icon: {
        type: Object,
        component: String,
        background: String,
      },
      default: () => [],
    },
  },
};
</script>

<style scoped>

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Fill the container height */
  padding: 0; /* Ensure no extra padding */
  box-sizing: border-box;
}

.image-wrapper {
  width: 100%; /* Fill the container width */
  height: 100%; /* Fill the container height */
  max-height: 400px; /* Limit the maximum height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent overflow */
  padding-top: 40px; /* Add space around the image (top, right, and left) */
  box-sizing: border-box; /* Include padding in width and height calculations */
}

.carousel-image {
  max-width: 100%; /* Image won't exceed the container's width */
  max-height: 100%; /* Image won't exceed the container's height */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Scale the image proportionally */
}


</style>
