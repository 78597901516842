<template>
    <div v-if="isOpen" class="modal-wrapper">
      <!-- Background overlay -->
      <div class="background-overlay"></div>
  
      <!-- Modal container -->
      <div class="modal-container">
        <!-- Close Button (Top-Right) -->
        <button class="close-button" @click="closeModal">
          <i class="fas fa-times"></i>
        </button>
  
              <!-- Check if tutorials array is empty -->
      <div v-if="!tutorials || tutorials.length === 0" class="empty-message">
        <p>No tutorials added yet. Check back later.</p>
      </div>

        <!-- Title Section -->
        <h2 v-else class="tutorial-title">{{ currentTutorialTitle }}</h2>
  
        <div class="content-wrapper">
          <!-- Video Section -->
          <div class="video-section">
            <video ref="videoPlayer" class="tutorial-video" controls>
              <source :src="currentVideo" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div v-if="!tutorials || tutorials.length === 0" class="empty-message">
        <p>No tutorials added yet. Check back later.</p>
      </div>
  
          <!-- Directory Section -->
          <div v-else class="directory-section">
            <h3 class="directory-title">Tutorials</h3>
            <ul class="tutorial-list">
              <li
                v-for="(tutorial, index) in tutorials"
                :key="index"
                class="tutorial-item"
                :class="{ active: index === currentVideoIndex }"
                @click="selectTutorial(index)"
              >
                {{ tutorial.title }}
              </li>
            </ul>
          </div>
        </div>
  
        <!-- Controls Section -->
        <div class="controls-section">
          <button @click="goBack" class="control-button">
            <i class="fas fa-arrow-left"></i>
          </button>
          <button @click="pauseVideo" class="control-button">
            <i class="fas fa-pause"></i>
          </button>
          <button @click="goNext" class="control-button">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      tutorials: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        currentVideoIndex: 0,
      };
    },
    computed: {
      currentVideo() {
        return this.tutorials[this.currentVideoIndex]?.url || '';
      },
      currentTutorialTitle() {
        return this.tutorials[this.currentVideoIndex]?.title || 'Untitled Tutorial';
      },
      hasTutorialForCurrentRoute() {
      const currentRoute = this.$route.path;
      const normalizedRoute = this.normalizeRoute(currentRoute);
      return this.tutorials.some((tutorial) => tutorial.route === normalizedRoute);
    },
    },
    methods: {
      selectTutorial(index) {
        this.currentVideoIndex = index;
        this.$refs.videoPlayer.load(); // Reload the video
      },
      goBack() {
        if (this.currentVideoIndex > 0) {
          this.currentVideoIndex--;
          this.$refs.videoPlayer.load();
        }
      },
      goNext() {
        if (this.currentVideoIndex < this.tutorials.length - 1) {
          this.currentVideoIndex++;
          this.$refs.videoPlayer.load();
        }
      },
      pauseVideo() {
        this.$refs.videoPlayer.pause();
      },
      closeModal() {
        this.$emit('close');
      },
      normalizeRoute(route) {
    // Remove IDs (numbers) from the route using a regular expression
    return route.replace(/\/\d+/g, '');
  },
  updateCurrentTutorial(route) {
    // Normalize the route to remove IDs
    const normalizedRoute = this.normalizeRoute(route);

    // Find the tutorial matching the normalized route
    const tutorial = this.tutorials.find(t => t.route === normalizedRoute);
    if (tutorial) {
      this.currentVideoIndex = this.tutorials.indexOf(tutorial);
      //this.$refs.videoPlayer.load(); // Reload the video
    } else {
      console.warn(`No tutorial found for route: ${normalizedRoute}`);
    }
  },
    },
  };
  </script>
  
  <style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2050;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2049;
  }
  
  .modal-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90vw;
    max-width: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    z-index: 2051;
    border: 2px solid #007bff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #6c757d;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #dc3545;
  }
  
  .tutorial-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100%;
  }
  
  .video-section {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tutorial-video {
    width: 100%;
    max-height: 80vh;
    aspect-ratio: 16 / 9; /* Maintain aspect ratio */
    border-radius: 8px;
    background-color: black;
  }
  
  .directory-section {
    flex: 1;
    overflow-y: auto;
    max-height: 45vh;
  }
  
  .directory-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .tutorial-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .tutorial-item {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
  }
  
  .tutorial-item.active {
    background-color: #007bff;
    color: white;
  }
  
  .tutorial-item:hover {
    background-color: #f0f0f0;
  }
  
  .controls-section {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .control-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .control-button:hover {
    background-color: #0056b3;
  }
  </style>
  