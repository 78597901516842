<template>
  <div v-if="isOpen" class="modal-wrapper">
    <!-- Background overlay -->
    <div class="background-overlay"></div>

    <!-- Modal container -->
    <div class="modal-container">
      <h2 class="title">Edit Account Details</h2>
      <form @submit.prevent="submitForm">
        <div class="form-row">
          <!-- Account Name Input -->
          <div class="form-group">
            <label for="accountName" class="form-label">Account Name</label>
            <input type="text" id="accountName" v-model="accountName" class="form-input" placeholder="Enter account name" />
          </div>

          <!-- Number of Employees Input -->
          <div class="form-group">
            <label for="numberOfEmployees" class="form-label">Number of Employees</label>
            <input
              type="text"
              id="numberOfEmployees"
              v-model="formattedNumberOfEmployees"
              class="form-input"
              placeholder="Enter number of employees"
              @input="formatNumberOfEmployees"
            />
          </div>
        </div>

        <div class="form-row">
          <!-- Email Input -->
          <div class="form-group">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" v-model="email" class="form-input" placeholder="Enter account email" />
          </div>

          <!-- Expiration Date Input -->
          <div class="form-group">
            <label for="expirationDate" class="form-label">Expiration Date</label>
            <input type="date" id="expirationDate" v-model="expirationDate" class="form-input" placeholder="MM/DD/YYYY" />
          </div>
        </div>

        <div class="form-row">
          <!-- Annual Premium Input -->
          <div class="form-group">
            <label for="annualPremium" class="form-label">Annual Premium</label>
            <input
              type="text"
              id="annualPremium"
              v-model="annualPremiumInput"
              class="form-input"
              placeholder="Enter annual premium"
              @input="handleAnnualPremiumInput"
            />
          </div>

          <!-- Probability to Close Input -->
          <div class="form-group">
            <label for="probabilityToClose" class="form-label">Probability to Close (%)</label>
            <input
              type="text"
              id="probabilityToClose"
              v-model="formattedProbabilityToClose"
              class="form-input"
              placeholder="Enter probability to close"
              @input="formatProbabilityToClose"
            />
          </div>
        </div>

        <div class="form-group checkbox-container">
        <label for="accountClosed" class="form-label checkbox-label">
          <input
            type="checkbox"
            id="accountClosed"
            v-model="accountClosed"
            class="form-checkbox"
          />
          Account Closed
        </label>
      </div>

        <!-- Buttons Container -->
        <div class="button-container" style="flex-grow: 1; display: flex; justify-content: flex-end;">
          <button type="button" @click="closeModal" class="cancel-button">
            Cancel
          </button>
          <button type="submit" class="save-button">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    accountData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      accountClosed: false,
      accountId: '',
      accountName: '',
      numberOfEmployees: '',
      formattedNumberOfEmployees: '',
      email: '',
      expirationDate: '',
      annualPremium: '', // Raw input value
      annualPremiumInput: '', // Input value for display
      formattedAnnualPremium: '', // Formatted value with $
      probabilityToClose: '', // Actual value
      formattedProbabilityToClose: '', // Displayed value
    };
  },
  watch: {
    accountData: {
      immediate: true,
      handler(newAccountData) {
        if (newAccountData) {
          this.setEditMode(newAccountData);
        } else {
          this.clearForm();
        }
      },
    },
  },

  methods: {
    handleAnnualPremiumInput(event) {
      // Remove non-numeric characters and commas
      const value = event.target.value.replace(/[^0-9]/g, '').trim();
      
      // Update `annualPremium` and `annualPremiumInput`
      this.annualPremium = value ? parseFloat(value) : '';
      this.annualPremiumInput = this.annualPremium ? `$${this.formatNumber(this.annualPremium)}` : '';
    },
    formatNumberOfEmployees(event) {
      const value = event.target.value.replace(/,/g, '').trim();
      this.numberOfEmployees = value ? parseInt(value, 10) : '';
      this.formattedNumberOfEmployees = this.numberOfEmployees ? this.formatNumber(this.numberOfEmployees) : '';
    },
    formatProbabilityToClose(event) {
      let value = event.target.value.replace('%', '').trim();
      if (!isNaN(value)) {
        this.probabilityToClose = parseFloat(value) || 0;
        this.formattedProbabilityToClose = `${this.probabilityToClose}%`;
      }
    },
    formatNumber(value) {
      if (!value || isNaN(value)) return '';
      return parseFloat(value).toLocaleString('en-US', { maximumFractionDigits: 0 });
    },
    setEditMode(account) {
      console.log("ACCOUNT DATA : ", account);
      this.accountId = account.accountId || 0;
      this.accountName = account.name || '';
      this.numberOfEmployees = account.numberOfEmployees || '';
      this.formattedNumberOfEmployees = this.formatNumber(this.numberOfEmployees);
      this.email = account.email || '';
      this.expirationDate = account.expirationDate || '';
      this.annualPremium = account.annualPremium || '';
      this.annualPremiumInput = this.annualPremium ? `$${this.formatNumber(this.annualPremium)}` : '';
      this.probabilityToClose = account.probabilityToClose ?? 90;
      this.formattedProbabilityToClose = `${this.probabilityToClose}%`;
      this.accountClosed = account.accountClosed === 1 ? true : false; // Convert 1 to true, 0 to false
    },
    async submitForm() {
      const updatedAccount = {
        name: this.accountName,
        numberOfEmployees: this.numberOfEmployees,
        email: this.email,
        expirationDate: this.expirationDate,
        annualPremium: this.annualPremium,
        probabilityToClose: this.probabilityToClose,
        accountClosed: this.accountClosed
      };

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/update-account/${this.accountId}`;

        const response = await axios.put(url, updatedAccount, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        console.log("Account updated successfully:", response.data);
        this.$emit('save', updatedAccount);
        this.closeModal();
      } catch (error) {
        console.error("Error updating account:", error);
        const errorMessage = error.response
          ? `Status: ${error.response.status} - ${error.response.data.message || error.message}`
          : error.message;
        alert(`Error updating: ${errorMessage}`);
      }
    },
    clearForm() {
      this.accountName = '';
      this.numberOfEmployees = '';
      this.formattedNumberOfEmployees = '';
      this.email = '';
      this.expirationDate = '';
      this.annualPremium = '';
      this.annualPremiumInput = '';
      this.formattedAnnualPremium = '';
      this.probabilityToClose = '';
      this.formattedProbabilityToClose = '';
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  margin-right: auto; /* Push the checkbox to the far left */
  display: flex;
  align-items: center;
}

.checkbox-label {
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.form-checkbox {
  margin-right: 8px; /* Space between checkbox and label text */
  width: 16px;
  height: 16px;
}


.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2049;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 50vw;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2051;
  position: relative;
  border: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  width: 48%;
}

.form-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 50px;
}

.cancel-button {
  background-color: #ccc;
  color: white;
  margin-right: 10px;
}

.save-button {
  background-color: #007bff;
  color: white;
}
</style>
